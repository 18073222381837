export class FacilityService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Exports the floor plans, spaces, and assets for a facility.
     * Returns the individual CSVs for the plans, spaces, and assets in the facility as a zip file.
     * Filename will be in the content-disposition header.
     * @param facilityId ID of the facility to export
     * @returns binary OK
     * @throws ApiError
     */
    getApiV1FacilityExport(facilityId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Facility/export/{facilityId}',
            path: {
                facilityId: facilityId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
