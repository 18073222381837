import { jsx as _jsx } from "react/jsx-runtime";
import { NavBar } from '@frontend/design-system/components/NavBar/NavBar';
import { OptimizeBoldIcon, OptimizeIcon, OrganizeBoldIcon, OrganizeIcon, OrgSettingsBoldIcon, OrgSettingsIcon, PrioritizeBoldIcon, PrioritizeIcon, SupportBoldIcon, SupportIcon, SystemBoldIcon, SystemIcon, } from '@frontend/design-system/theme/icons';
import { domainReports } from '@frontend/domain/contexts/Reports/ReportsProvider';
import { IAppNames } from '@frontend/domain/models/App';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ExternalPaths, Paths } from '../../config/paths';
import LogoFull from '../../images/logoFull.png';
import LogoSmall from '../../images/LogoSmall.png';
import { SystemRoleEnum } from '../../objects/UserData';
import { usePlatformOrgApi } from '../../services/api/platformOrgApiHook';
import { useAppSelector } from '../../store/hooks';
import { memoizedSelectOrganization } from '../../store/orgSlice';
import { selectUserData } from '../../store/userDataSlice';
import { useCurrentUserAppAccess } from '../hooks/useAppAccess';
export var NavLinkName;
(function (NavLinkName) {
    NavLinkName["ORGANIZE"] = "Organize";
    NavLinkName["PRIORITIZE"] = "Prioritize";
    NavLinkName["OPTIMIZE"] = "Optimize";
    NavLinkName["SYSTEM_ADMIN"] = "System Admin";
    NavLinkName["ORGANIZATION_SETTINGS"] = "Organization Settings";
    NavLinkName["SUPPORT"] = "Support";
})(NavLinkName || (NavLinkName = {}));
export const ExpandableSidebar = ({ children, }) => {
    var _a, _b, _c;
    const currentUser = useAppSelector(selectUserData);
    const { org } = useAppSelector(memoizedSelectOrganization);
    const { [IAppNames.PIQ]: hasPIQAccess } = useCurrentUserAppAccess();
    const { userReports } = usePlatformOrgApi();
    const [reports, setReports] = useState();
    const location = useLocation();
    const currentURL = location.pathname;
    useEffect(() => {
        if (org === null || org === void 0 ? void 0 : org.orgIdentity) {
            userReports(org === null || org === void 0 ? void 0 : org.orgIdentity).then((reports) => {
                setReports(domainReports(reports));
            });
        }
    }, [org === null || org === void 0 ? void 0 : org.orgIdentity]);
    const isSublinkCurrent = (sublinkURL) => {
        return currentURL === sublinkURL;
    };
    const mapSublinks = (sublinks) => {
        return sublinks.map((sublink) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, sublink), { isCurrentLink: isSublinkCurrent((_b = (_a = sublink.link) === null || _a === void 0 ? void 0 : _a.href) !== null && _b !== void 0 ? _b : '') }));
        });
    };
    const spreadReports = (reportList) => {
        return reportList && reportList.length > 0
            ? [
                {
                    title: 'Reports',
                    subGroups: reportList.map((report) => ({
                        title: report.name,
                        link: {
                            href: org && report
                                ? ExternalPaths.getReportUrl(org.id, report.id.toString())
                                : '',
                        },
                    })),
                },
            ]
            : [];
    };
    return (_jsx(NavBar, { squishContent: true, user: {
            name: (_a = `${currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName} ${currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName}`) !== null && _a !== void 0 ? _a : '',
            systemRole: (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole) !== null && _b !== void 0 ? _b : '',
        }, assets: {
            logoSmallSrc: LogoSmall,
            logoLargeSrc: LogoFull,
        }, links: {
            home: {
                href: Paths.HOME,
            },
            profile: {
                href: Paths.USER_PROFILE,
            },
            logout: {
                href: Paths.LOGOUT,
            },
        }, linkGroups: [
            {
                title: NavLinkName.ORGANIZE,
                icon: OrganizeIcon,
                selectedIcon: OrganizeBoldIcon,
                subLinks: mapSublinks([
                    {
                        title: 'FCA Tool',
                        link: {
                            href: (org === null || org === void 0 ? void 0 : org.id)
                                ? ExternalPaths.getFCAOrgUrl(org.id)
                                : ExternalPaths.getFCAUrl(),
                            external: true,
                        },
                    },
                    {
                        title: 'myGallery',
                        link: {
                            href: (org === null || org === void 0 ? void 0 : org.id)
                                ? ExternalPaths.getMyOrgGalleryUrl(org === null || org === void 0 ? void 0 : org.id)
                                : ExternalPaths.getMyGalleryUrl(),
                            external: true,
                        },
                    },
                    ...spreadReports(reports === null || reports === void 0 ? void 0 : reports.organize),
                ]),
            },
            {
                title: NavLinkName.PRIORITIZE,
                icon: PrioritizeIcon,
                selectedIcon: PrioritizeBoldIcon,
                subLinks: [
                    {
                        title: 'myProjects',
                        link: {
                            href: (org === null || org === void 0 ? void 0 : org.id)
                                ? ExternalPaths.getMyOrgProjectsUrl(org === null || org === void 0 ? void 0 : org.id)
                                : ExternalPaths.getMyProjectsUrl(),
                            external: true,
                        },
                    },
                    {
                        title: 'myScenarios',
                        link: {
                            href: (org === null || org === void 0 ? void 0 : org.id)
                                ? ExternalPaths.getMyOrgScenariosUrl(org === null || org === void 0 ? void 0 : org.id)
                                : ExternalPaths.getMyScenariosUrl(),
                            external: true,
                        },
                    },
                    {
                        title: 'myAssets',
                        link: {
                            href: (org === null || org === void 0 ? void 0 : org.id)
                                ? ExternalPaths.getMyOrgAssetsUrl(org === null || org === void 0 ? void 0 : org.id)
                                : ExternalPaths.getMyAssetsUrl(),
                            external: true,
                        },
                    },
                    ...(hasPIQAccess
                        ? [
                            {
                                title: 'myOpportunities',
                                link: {
                                    href: ExternalPaths.getMyOpportunitiesUrl(org === null || org === void 0 ? void 0 : org.orgIdentity),
                                    external: true,
                                },
                            },
                        ]
                        : []),
                    ...spreadReports(reports === null || reports === void 0 ? void 0 : reports.prioritize),
                ],
            },
            {
                title: NavLinkName.OPTIMIZE,
                icon: OptimizeIcon,
                selectedIcon: OptimizeBoldIcon,
                subLinks: [...spreadReports(reports === null || reports === void 0 ? void 0 : reports.optimize)],
                divideAfter: true,
            },
            ...((currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole) === SystemRoleEnum.SUPER_ADMIN ||
                (currentUser === null || currentUser === void 0 ? void 0 : currentUser.systemRole) === SystemRoleEnum.ORG_APPROVER
                ? [
                    {
                        title: NavLinkName.SYSTEM_ADMIN,
                        icon: SystemIcon,
                        selectedIcon: SystemBoldIcon,
                        link: {
                            href: ExternalPaths.getSystemAdminUrl(),
                        },
                    },
                ]
                : []),
            ...(org
                ? [
                    {
                        title: NavLinkName.ORGANIZATION_SETTINGS,
                        icon: OrgSettingsIcon,
                        selectedIcon: OrgSettingsBoldIcon,
                        link: {
                            href: ExternalPaths.getOrgSettingsUrl((_c = org === null || org === void 0 ? void 0 : org.id) !== null && _c !== void 0 ? _c : ''),
                        },
                    },
                ]
                : []),
            {
                title: NavLinkName.SUPPORT,
                icon: SupportIcon,
                selectedIcon: SupportBoldIcon,
                link: {
                    href: Paths.SUPPORT,
                },
            },
        ], children: children }));
};
