var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, FormLabel, HStack, Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, VStack, } from '@chakra-ui/react';
import { components, CreatableSelect, Select as ChakraSelect, } from 'chakra-react-select';
import React, { useMemo } from 'react';
import { variants } from '../../theme/theme';
function BareModal({ disclosure, labels, children, size = '2xl', onClear, }) {
    return (_jsxs(ChakraModal, Object.assign({}, disclosure, { size: size, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { borderRadius: '15px', children: [_jsxs(ModalHeader, { position: 'relative', mx: '40px', mt: '40px', mb: '20px', p: '0', fontSize: '36px', fontWeight: '400', children: [_jsx(ModalCloseButton, { position: 'absolute', top: '0', right: '0', onClick: onClear }), labels.title] }), _jsx(ModalBody, { width: '100%', px: '40px', children: children })] })] })));
}
function Modal({ disclosure, onApply, onClear, labels, applyDisabled = false, children, size = '2xl', showButtons = true, submitOverride, }) {
    const handleApply = (event) => __awaiter(this, void 0, void 0, function* () {
        if (event) {
            event.preventDefault();
            if (onApply) {
                onApply()
                    .then(() => disclosure.onClose())
                    .catch(() => { });
            }
            else {
                disclosure.onClose();
            }
        }
    });
    return (_jsxs(ChakraModal, Object.assign({}, disclosure, { size: size, children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { borderRadius: '15px', children: [_jsxs(ModalHeader, { position: 'relative', mx: '40px', mt: '40px', mb: '20px', p: '0', fontSize: '36px', fontWeight: '400', children: [_jsx(ModalCloseButton, { position: 'absolute', top: '0', right: '0', onClick: disclosure.onClose }), labels.title] }), _jsx(ModalBody, { width: '100%', px: '40px', children: _jsxs("form", { onSubmit: handleApply, "data-testid": 'form', id: `${labels.title}_form`, children: [_jsx(HStack, { gap: '40px', alignItems: 'flex-start', children: children }), showButtons && (_jsxs(HStack, { justify: 'space-between', my: '40px', children: [_jsx(Button, { variant: variants.blueOutlineBtn, onClick: onClear, children: labels.cancel }), _jsx(Button, { "aria-label": `${labels.apply}`, variant: variants.blueBtn, isDisabled: applyDisabled, _hover: { bg: '' }, type: submitOverride ? 'button' : 'submit', onClick: submitOverride, children: labels.apply })] }))] }) })] })] })));
}
function Column({ wrap, children, stackProps }) {
    return (_jsx(VStack, Object.assign({ gap: '20px', flexGrow: 1, height: 'inherit', wrap: wrap ? 'wrap' : 'nowrap' }, stackProps, { children: children })));
}
function Dropdown({ label, value, selectFrom, onChange, variant, hidden = true, }) {
    return (_jsxs(FormControl, { children: [label && _jsx(FormLabel, { htmlFor: label, children: label }), _jsxs(Select, { value: value !== null && value !== void 0 ? value : 'none', onChange: onChange, variant: variant, children: [_jsx("option", { value: '', hidden: hidden, children: "Select" }), selectFrom.map((option) => typeof option === 'object' ? (_jsx("option", { value: option.value, children: option.label }, `select-${label}-${option.value}`)) : (_jsx("option", { value: option, children: option }, `select-${label}-${option}`)))] })] }));
}
function MultiSelectDropdown({ isMulti, label, selectFrom, value, onChange, useBasicStyles, }) {
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { htmlFor: label, children: label }), _jsx(ChakraSelect, { isMulti: isMulti, options: selectFrom, placeholder: 'Select', value: value, onChange: onChange, name: label, inputId: label, colorScheme: 'orange', useBasicStyles: useBasicStyles, menuPosition: 'fixed' })] }));
}
function SearchableDropdown({ label, selectFrom, isClearable = false, value, onChange, }) {
    return (_jsxs(FormControl, { children: [_jsx(FormLabel, { htmlFor: label, children: label }), _jsx(ChakraSelect, { isMulti: false, isClearable: isClearable, options: selectFrom, placeholder: 'Select', value: value, onChange: onChange, name: label, inputId: label })] }));
}
/** Allows for user search/select of an option as well as creating a new option
 *    on create of new option an additional field is added to the
 *    `newValue: SingleValue<Option>` passed in to onChange as `__isNew__: true`
 *    This allows for conditional creation behavior on the input.
 */
function CreatableDropdown({ label, selectFrom, placeholder, value, onChange, onInputChange, isValidNewOption = true, filterOption, maxLength = 30, chakraStyles, }) {
    const customInputWithMaxLength = useMemo(() => {
        return (inputProps) => (_jsx(components.Input, Object.assign({}, inputProps, { maxLength: maxLength })));
    }, [maxLength]);
    return (_jsxs(FormControl, { children: [label && _jsx(FormLabel, { htmlFor: label, children: label }), _jsx(CreatableSelect, { isMulti: false, options: selectFrom, placeholder: placeholder, value: value, onChange: onChange, onInputChange: onInputChange, name: label, inputId: label, isValidNewOption: () => isValidNewOption, filterOption: filterOption, chakraStyles: chakraStyles, components: { Input: customInputWithMaxLength } })] }));
}
export const Form = {
    BareModal,
    Modal,
    Column,
    Dropdown,
    MultiSelectDropdown,
    SearchableDropdown,
    CreatableDropdown,
};
