var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Box, ChakraProvider, HStack, Spinner } from '@chakra-ui/react';
import '@frontend/design-system/theme/font';
import theme from '@frontend/design-system/theme/theme';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { authConfig } from './authConfig';
import { ReportsProvider } from './components/context/ReportsContext';
import { Paths } from './config/paths';
import ActivatePage from './pages/ActivatePage';
import { LandingPage } from './pages/LandingPage';
import { LogoutPage } from './pages/LogoutPage';
import { OrganizationDetailsPage } from './pages/OrganizationDetails/OrganizationDetailsPage';
import { UserProfilePage } from './pages/Profile/UserProfile/UserProfilePage';
import { ReportPage } from './pages/Report/ReportPage';
import { SupportPage } from './pages/SupportPage';
import { SystemAdminPage } from './pages/SystemAdmin/SystemAdminPage';
import { apiService, platformApiService } from './services';
import { AuthenticationGuard } from './sharedComponents/auth/AuthenticationGuard';
import { NoAssociatedData } from './sharedComponents/NoAssociatedData';
import { fetchFeatureFlags } from './store/featureFlagSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectLoadingSpinner } from './store/orgSlice';
import { fetchUser, selectAccess, selectUserData } from './store/userDataSlice';
function App() {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectUserData);
    const loadingSpinner = useAppSelector(selectLoadingSpinner);
    const clientAccess = useAppSelector(selectAccess);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [loadingUser, setLoadingUser] = useState(true);
    const isUser = currentUser ? true : false;
    const hasClientAccess = isUser && !loadingUser && clientAccess;
    const { setToken, isReady } = useDependencies();
    useEffect(() => {
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.email)) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: authConfig.authorizationParams.platformAudience,
                    scope: 'email read:current_user update:current_user_metadata',
                },
            }).then((token) => {
                setToken(token);
            });
            platformApiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            apiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            dispatch(fetchUser());
        }
    }, [isAuthenticated, user]);
    useEffect(() => {
        if (currentUser && currentUser.id !== 'null' && isReady) {
            setLoadingUser(false);
        }
    }, [currentUser, setLoadingUser, isReady]);
    useEffect(() => {
        dispatch(fetchFeatureFlags());
    }, []);
    return (_jsx(ChakraProvider, { theme: theme, children: _jsx(ReportsProvider, { children: _jsxs(Box, { w: '100%', h: '100vh', children: [_jsxs(Routes, { children: [_jsx(Route, { path: Paths.LOGOUT, element: _jsx(LogoutPage, {}) }), _jsx(Route, { path: Paths.ACTIVATE_PAGE, element: _jsx(ActivatePage, {}) }), _jsxs(Route, { path: Paths.HOME, children: [_jsx(Route, { path: '', element: _jsx(AuthenticationGuard, { component: () => _jsx(LandingPage, {}) }) }), _jsx(Route, { path: ':oid', element: _jsx(AuthenticationGuard, { component: () => _jsx(LandingPage, {}) }) })] }), _jsx(Route, { path: Paths.SUPPORT, element: _jsx(AuthenticationGuard, { access: isUser, noAccessComponent: () => (_jsx(NoAssociatedData, {})), component: () => _jsx(SupportPage, {}) }) }), _jsx(Route, { path: Paths.USER_PROFILE, element: _jsx(AuthenticationGuard, { access: isUser, noAccessComponent: () => (_jsx(NoAssociatedData, {})), component: () => _jsx(UserProfilePage, {}) }) }), _jsx(Route, { path: Paths.ORG_DETAILS_CREATE, element: _jsx(AuthenticationGuard, { access: hasClientAccess, component: () => (_jsx(OrganizationDetailsPage, {})) }) }), _jsx(Route, { path: Paths.ORG_DETAILS_ID, element: _jsx(AuthenticationGuard, { access: hasClientAccess, component: () => (_jsx(OrganizationDetailsPage, {})) }) }), _jsx(Route, { path: Paths.SYSTEM_ADMIN, element: _jsx(AuthenticationGuard, { component: () => _jsx(SystemAdminPage, {}) }) }), _jsx(Route, { path: Paths.REPORT_DYNAMIC, element: _jsx(AuthenticationGuard, { access: hasClientAccess, component: () => _jsx(ReportPage, {}) }) })] }), loadingSpinner && (_jsx(HStack, { zIndex: '90000', w: '100%', h: '100%', position: 'absolute', top: '0', left: '0', bottom: '0', right: '0', backgroundColor: 'rgb(0,0,0, 0.7)', children: _jsx(Spinner, { margin: 'auto', thickness: '4px', speed: '0.65s', emptyColor: 'gray.200', color: 'blue.500', size: 'xl' }) }))] }) }) }));
}
export default App;
